
import postData from '@/assets/taiwan_districts.json'
export default {
  data () {
    return {
      // 驗證碼規則
      identifyCodes: '1234567890',
      identifyCodes2: '1234567890',
      // isSubCities cities與tstar同樣
      isSubCities: ['臺北-北區', '臺北-南區', '臺北-東區', '臺北-西區', '新北-北區', '新北-南區', '新北-東區', '新北-西區'],
      cities: ['臺北', '新北', '基隆', '宜蘭', '桃園', '新竹', '苗栗',
        '臺中', '彰化', '南投', '雲林', '嘉義', '臺南', '高雄', '屏東',
        '花蓮', '臺東', '小琉球', '綠島', '蘭嶼', '金門', '澎湖', '馬祖'],

      tourOpt: {
        labels: {
          buttonSkip: '跳過說明',
          buttonPrevious: '上一步',
          buttonNext: '下一步',
          buttonStop: '完成'
        }
      },
      transportTypeOpt: [
        { text: '甲種', value: '甲種' },
        { text: '乙種', value: '乙種' },
        { text: '九坐', value: '九坐' }
      ],
      travelArea: {
        ta01: { text: '台灣', value: false },
        ta02: { text: '東南亞', value: false },
        ta03: { text: '東北亞', value: false },
        ta04: { text: '中國', value: false },
        ta05: { text: '歐洲', value: false },
        ta06: { text: '北美洲', value: false },
        ta07: { text: '中美洲', value: false },
        ta08: { text: '南美洲', value: false },
        ta09: { text: '大洋洲', value: false },
        ta10: { text: '非洲', value: false },
        ta11: { text: '中東', value: false },
        ta12: { text: '南極', value: false },
        ta13: { text: '高爾夫', value: false },
        ta14: { text: '郵輪', value: false }
      },
      worldArea: {
        台灣: { val: false, item: ['北部', '中部', '南部', '東部', '離島'] },
        東南亞: { val: false, item: ['泰國', '越南', '印尼', '馬來西亞', '菲律賓', '新加坡'] },
        東北亞: { val: false, item: ['日本', '韓國', '香港', '澳門'] },
        中國: { val: false, item: ['華北', '東北', '華東', '華南', '西南', '西北', '華中'] },
        歐洲: { val: false, item: ['英國', '法國', '德國', '義大利', '希臘', '保加利亞', '瑞典', '挪威', '冰島', '愛爾蘭', '西班牙', '葡萄牙', '荷蘭', '比利時', '丹麥', '奧地利', '捷克', '瑞士', '波蘭', '匈牙利', '羅馬尼亞', '克羅埃西亞'] },
        北美洲: { val: false, item: ['美國', '加拿大', '墨西哥'] },
        中美洲: { val: false, item: ['貝里斯', '瓜地馬拉', '宏都拉斯', '尼加拉瓜', '哥斯大黎加', '巴拿馬', '薩爾瓦多', '洪都拉斯'] },
        南美洲: { val: false, item: ['巴西', '阿根廷', '智利', '秘魯', '哥倫比亞', '玻利維亞', '厄瓜多', '巴拉圭', '烏拉圭', '委內瑞拉', '法屬圭亞那', '荷屬安地列斯', '蘇利南'] },
        大洋洲: { val: false, item: ['澳洲', '紐西蘭', '斐濟', '薩摩亞', '東加等太平洋島國'] },
        非洲: { val: false, item: ['埃及', '摩洛哥', '肯亞', '南非', '坦桑尼亞', '衣索比亞', '加納', '賴比瑞亞', '尼日利亞', '突尼西亞', '茅利塔尼亞', '馬達加斯加', '馬利', '莫桑比克', '納米比亞', '獅子山', '蘇丹', '坦尚尼亞', '烏干達', '辛巴威'] },
        中東: { val: false, item: ['以色列', '伊朗', '沙烏地阿拉伯', '阿聯酋', '科威特', '黎巴嫩', '卡達', '敘利亞', '土耳其', '葉門'] },
        南極: { val: false, item: [] },
        高爾夫: { val: false, item: ['台灣', '日本', '韓國', '中國大陸', '越南', '菲律賓', '印尼', '馬來西亞', '泰國'] },
        郵輪: { val: false, item: ['歐洲遊輪', '美洲遊輪', '亞洲遊輪', '紐澳遊輪', '台灣港口出發'] }
      },
      // worldArea2: {
      //   東南亞: ['泰國', '越南', '印尼', '馬來西亞', '菲律賓', '新加坡'],
      //   東北亞: ['日本', '韓國', '香港', '澳門'],
      //   中國: ['華北', '東北', '華東', '華南', '西南', '西北', '華中'],
      //   歐洲: ['英國', '法國', '德國', '義大利', '希臘', '保加利亞', '瑞典', '挪威', '冰島', '愛爾蘭', '西班牙', '葡萄牙', '荷蘭', '比利時', '丹麥', '奧地利', '捷克', '瑞士', '波蘭', '匈牙利', '羅馬尼亞', '克羅埃西亞'],
      //   北美洲: ['美國', '加拿大', '墨西哥'],
      //   中美洲: ['貝里斯', '瓜地馬拉', '宏都拉斯', '尼加拉瓜', '哥斯大黎加', '巴拿馬', '薩爾瓦多', '洪都拉斯'],
      //   南美洲: ['巴西', '阿根廷', '智利', '秘魯', '哥倫比亞', '玻利維亞', '厄瓜多', '巴拉圭', '烏拉圭', '委內瑞拉', '法屬圭亞那', '荷屬安地列斯', '蘇利南'],
      //   大洋洲: ['澳洲', '紐西蘭', '斐濟', '薩摩亞', '東加等太平洋島國'],
      //   非洲: ['埃及', '摩洛哥', '肯亞', '南非', '坦桑尼亞', '衣索比亞', '加納', '賴比瑞亞', '尼日利亞', '突尼西亞', '茅利塔尼亞', '馬達加斯加', '馬利', '莫桑比克', '納米比亞', '獅子山', '蘇丹', '坦尚尼亞', '烏干達', '辛巴威'],
      //   中東: ['以色列', '伊朗', '沙烏地阿拉伯', '阿聯酋', '科威特', '黎巴嫩', '卡達', '敘利亞', '土耳其', '葉門'],
      //   台灣: ['北部', '中部', '南部', '東部', '離島']
      // },
      priceRange: {
        ta01: { text: '1萬以下', value: '0-10000' },
        ta02: { text: '1萬 - 5萬', value: '10000-50000' },
        ta03: { text: '5萬 - 10萬', value: '50000-100000' },
        ta04: { text: '10萬以上 ', value: '100000-1000000' }
      },
      travelIndustryOptions: [
        { text: '民宿與飯店', value: 'ti1', url: 'hotel-industry' },
        { text: '交通業者', value: 'ti2', url: 'transportation-operator' },
        { text: '旅行社', value: 'ti3', url: 'travel-agency' },
        { text: '餐飲業 ', value: 'ti4', url: 'restaurant-industry' },
        { text: '伴手禮 ', value: 'ti6', url: 'gift-shop' },
        { text: '景點', value: 'ti5', url: 'tourist-attraction' }
      ],
      industryOptions: [
        { text: '農、林、漁、牧業', value: 'a' },
        { text: '礦業及土石採取業', value: 'b' },
        { text: '製造業', value: 'c' },
        { text: '電力及燃氣供應業', value: 'd' },
        { text: '用水供應及污染整治業 ', value: 'e' },
        { text: '營建工程業 ', value: 'f' },
        { text: '批發及零售業', value: 'g' },
        { text: '運輸及倉儲業', value: 'h' },
        { text: '住宿及餐飲業', value: 'i' },
        { text: '出版影音及資通訊業', value: 'j' },
        { text: '金融及保險業', value: 'k' },
        { text: '不動產業', value: 'l' },
        { text: '專業、科學及技術服務業', value: 'm' },
        { text: '支援服務業', value: 'n' },
        { text: '公共行政及國防；強制性社會安全', value: 'o' },
        { text: '教育業', value: 'p' },
        { text: '醫療保健及社會工作服務業', value: 'q' },
        { text: '藝術、娛樂及休閒服務業 ', value: 'r' },
        { text: '其他服務業', value: 's' }
      ],
      categoryLocationTW: [
        {
          label: '北部',
          options: ['臺北', '新北', '基隆', '宜蘭', '桃園', '新竹', '苗栗']
        },
        {
          label: '中部',
          options: ['臺中', '彰化', '南投']
        },
        {
          label: '南部',
          options: ['雲林', '嘉義', '臺南', '高雄', '屏東']
        },
        {
          label: '東部',
          options: ['花蓮', '臺東']
        },
        {
          label: '離島',
          options: ['小琉球', '綠島', '蘭嶼', '金門', '澎湖', '馬祖']
        }
      ],
      categoryLocation: [
        {
          label: '北部',
          options: ['臺北', '新北', '基隆', '宜蘭', '桃園', '新竹', '苗栗']
        },
        {
          label: '中部',
          options: ['臺中', '彰化', '南投']
        },
        {
          label: '南部',
          options: ['雲林', '嘉義', '臺南', '高雄', '屏東']
        },
        {
          label: '東部',
          options: ['花蓮', '臺東']
        },
        {
          label: '離島',
          options: ['小琉球', '綠島', '蘭嶼', '金門', '澎湖', '馬祖']
        },
        {
          label: '日本',
          options: ['北海道', '東北', '關東', '關西', '四國', '九州', '沖繩']
        },
        {
          label: '東南亞',
          options: ['泰國', '菲律賓', '印尼', '新加坡、馬來西亞', '柬埔寨', '越南']
        }],
      customToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{
        align: ''
      }, {
        align: 'center'
      }, {
        align: 'right'
      }, {
        align: 'justify'
      }], ['blockquote', 'code-block'], [{
        list: 'ordered'
      }, {
        list: 'bullet'
      }, {
        list: 'check'
      }],
      [{
        color: []
      }, {
        background: []
      }], // dropdown with defaults from theme
      ['link', 'clean'] // remove formatting button
      ]
    }
  },
  computed: {
    // 原始資料
    postData () {
      return postData
    },
    // 縣市選項
    cityOpt () {
      const opt = []
      postData.forEach(item => {
        opt.push({ value: item.name, text: item.name })
      })
      return opt
    },
    // 鄉鎮區選項
    districtsOpt (city) {
      const districts = []
      if (this.city.length > 0) {
        this.postData.forEach(item => {
          if (item.name === this.city) {
            item.districts.forEach(item => {
              districts.push({ value: item.name, text: item.name })
            })
          }
        })
      }
      return districts
    },
    now () {
      return Date.now()
    },
    user () {
      return this.$store.getters['user/user']
    }
  },
  methods: {
    // 重新產生驗證碼
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      // console.log(this.identifyCode)
    },
    // 生成隨機驗證碼
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
        ]
      }
    },
    refreshCode2 () {
      this.identifyCode2 = ''
      this.makeCode2(this.identifyCodes2, 4)
      // console.log(this.identifyCode2)
    },
    // 生成隨機驗證碼
    makeCode2 (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode2 += this.identifyCodes2[
          Math.floor(Math.random() * (this.identifyCodes2.length - 0) + 0)
        ]
      }
    },
    showNavName (name) {
      this.$store.commit('showNavName', name)
      // console.log(name)
    }
  }

}
